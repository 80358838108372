import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, catchError, tap, throwError, map } from 'rxjs';
import { FILE_UPLOAD_V2, API } from '@configs/api-endpoints';
import { UploadedFileResponse } from '@shared/models/uploaded-file.model';
import { FileUploadMetadata } from '@shared/models/file-upload-metadata.model';
import { Store } from '@ngxs/store';
import { Injectable } from '@angular/core';
import { BulkOrderProcessingResult } from '@shared/models/bulk-order-processing-result.model';

@Injectable({
  providedIn: 'root',
})
export class FileUploadService {
  private authToken: string | null = null;

  constructor(
    private http: HttpClient,
    private store: Store
  ) {
    const state = this.store.selectSnapshot(state => state.auth);
    this.authToken = state ? state.token : null;
  }

  uploadSingleFile(file: File, metadata: FileUploadMetadata): Observable<UploadedFileResponse> {
    const formData: FormData = new FormData();
    formData.append('file', file, file.name);

    let URL = `${FILE_UPLOAD_V2}/upload?path=${encodeURIComponent(metadata.path)}`;
    if (metadata.orgId) {
      URL += `&orgId=${encodeURIComponent(metadata.orgId)}`;
    }

      // Add optional form data
    Object.entries(metadata).forEach(([key, value]) => {
      if (value !== undefined && key !== 'path' && key !== 'orgId') {
        formData.append(key, value.toString());
      }
    });

    const headers = new HttpHeaders({
      Authorization: `Bearer ${this.authToken}`,
    });

    return this.http.post<UploadedFileResponse>(URL, formData, { headers }).pipe(
      tap(response => console.log('Upload response:', response)),
      catchError(error => {
        console.error('Error uploading file:', error);
        return throwError(() => new Error('Error uploading file'));
      })
    );
  }

  processBulkOrderFile(file: File, orgId: string, operationId: string): Observable<BulkOrderProcessingResult> {
    const formData = new FormData();
    formData.append('file', file);
    formData.append('org_id', orgId);
    formData.append('operation_id', operationId);

    const URL = `${API.fileParser.processBulkOrders}`;

    formData.forEach((value, key) => {
      console.log(key, value);
    });

    const headers = new HttpHeaders({
      Authorization: `Bearer ${this.authToken}`,
    });

    return this.http.post<any>(URL, formData, { headers }).pipe(
      map(response => this.parseBulkOrderResponse(response)),
      tap(result => console.log('Parsed bulk order result:', result)),
      catchError(error => {
        console.error('Error processing bulk order file:', error);
        console.error('Error response:', error.error);
        return throwError(() => new Error('Error processing bulk order file'));
      })
    );
  }

  private parseBulkOrderResponse(response: any[]): BulkOrderProcessingResult {
    const [details, errors, errorFileUrl] = response;
    return {
      jobFileDetails: details || {},
      errors: errors || [],
      errorFileUrl: errorFileUrl || null
    };
  }
}
